<template>
  <div>
    <customer-information class="mb-1" />
    <insurance-information
      class="mb-1"
    />
  </div>
</template>

<script>
import config from '@/views/main/orders/view/rental-sales/config'
import CustomerInformation from './components/CustomerInformation.vue'
import InsuranceInformation from './components/InsuranceInformation.vue'
import DispatchAndReturn from './components/DispatchAndReturn.vue'

export default {
  name: 'FormPieceAppearOnCustomerSelect',
  components: {
    CustomerInformation,
    InsuranceInformation,
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
  computed: {
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
  },
}
</script>
