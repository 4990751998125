<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(INSURANCE_INFORMATION_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <div class="position-relative">
          <component
            :is="insuranceInformationFields[field].type"
            v-for="field in [ 'policy_number']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="insuranceInformation[field]"
            :is-editable="true"
            class="h-auto"
            v-bind="getProps(field, insuranceInformationFields)"
          />
          <feather-icon
            icon="LStockEyeIcon"
            class="ml-1 cursor-pointer eyeIcon"
            size="32"
            @click="gotoNewPage(`/customers/${customerId}/verification/`, $event, '_blank')"
          />
        </div>
        <component
          :is="insuranceInformationFields[field].type"
          v-for="field in [ 'amount_insured']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="insuranceInformation[field]"
          :is-editable="true"
          class="h-auto"
          v-bind="getProps(field, insuranceInformationFields)"
        />
      </b-col>
      <b-col md="6">
        <component
          :is="insuranceInformationFields['deductible'].type"
          ref="deductible_ref"
          v-model="insuranceInformation['deductible']"
          :is-editable="true"
          class="h-auto"
          v-bind="getProps('deductible', insuranceInformationFields)"
        />
        <label class="pl-1 font-weight-bolder">{{ $t('Coverage Dates') }}</label>
        <div
          class="verification-data-pickers"
        >
          <div class="verification-data-pickers__wrapper d-flex">
            <component
              :is="insuranceInformationFields[field].type"
              v-for="field in ['coverage_start_date', 'coverage_end_date']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="insuranceInformation[field]"
              class="w-100"
              v-bind="getProps(field, insuranceInformationFields)"
              :is-editable="true"
            />
          </div>
          <small
            v-show="isTodayOrFuture"
            class="text-danger"
          >Insurance is outdated</small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import { isFuture, isToday } from 'date-fns'
import config from '../config'

export default {
  name: 'InsuranceInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    insuranceInformation() {
      return this.$store.state[this.MODULE_NAME].insuranceInformationForm
    },
    customerId() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id
    },
    isTodayOrFuture() {
      const endDate = this.insuranceInformation?.coverage_end_date
      return endDate ? isToday(endDate) ? false : !isFuture(endDate) : false
    },
  },
  methods: {

  },
  setup() {
    const {
      MODULE_NAME,
      insuranceInformationFields,
      INSURANCE_INFORMATION_FORM_TITLE,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      insuranceInformationFields,
      INSURANCE_INFORMATION_FORM_TITLE,
    }
  },
}
</script>
<style lang="scss">
.verification-data-pickers {
  &__wrapper {

    label {
      display: none !important;
    }

    & .form-group:first-child .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    & .form-group:last-child .form-control {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: none;
    }
  }

}
.eyeIcon{
  position: absolute;
  top: 30px;
  right: 5px;
}
</style>
