<template>
  <div class="form-wrapper">
    <b-row>
      <b-col cols="6">
        <div class="d-flex align-center">
          <h4 class="font-weight-bolder mb-0 d-flex align-items-center">
            {{ $t(ORDER_ITEMS_RENTALS_TABLE_TITLE) }}
          </h4>
          <autosuggest
              id="rental-product"
              ref="autocomplete"
              v-model="searchQuery"
              class="w-100 ml-1 mr-1"
              :suggestions="suggestions"
              :input-props="getSearchInputProps()"
              v-bind="getSuggestProps()"
              :table-suggestion="true"
              :is-editable="!isDisabledAutosuggest()"
              :table-suggestion-configs="rentalTableAutoSuggestTableColumns"
              :is-disabled-function-auto-suggest="isDisabledAction"
              @input="fetchResults"
              @selected="selectHandler"
          >
            <template slot="after-input">
              <b-spinner
                  v-if="searchLoading"
                  class="spinner"
                  variant="secondary"
                  small
              />
            </template>

          </autosuggest>
        </div>
      </b-col>
      <b-col>
        <b-form-checkbox
            v-model="allowToPickRentalTimesByLineItem"
            @change="allowToPickRentalTimesByLineItemChange"
        >
          {{ $t("Allow to pick rental times by line item ") }}
        </b-form-checkbox>
        <b-form-checkbox
            v-model="canBeSubRented"
        >
          {{ $t("Show Sub-Rental Items Only") }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <!--    start_date: eventInformationRentalStart || new Date(),-->
    <!--    end_date: eventInformationRentalEnd || new Date(),-->
    <!--    event_information_rental_start_time: eventInformationRentalStartDispatchTime || format(new Date(), 'HH:mm:ss'),-->
    <!--    event_information_rental_end_time: eventInformationRentalEndTime || format(new Date(), 'HH:mm:ss'),-->
    <l-draggable-table-list-collector
        ref="lTableRef"
        :is-searchable="false"
        :module-name="MODULE_NAME"
        :fetched-data="fetchedRentals()"
        :table-columns="tableColumnsRental"
        :additional-object-for-selected-item="{
        notes: '',
        isNoteOpen: false,
        inheritFromOrder: true,
        type: this.canBeSubRented ? 'sub-rent' :'rent'
      }"
        :is-dragan-able="false"
        :table-suggestion-configs="rentalTableAutoSuggestTableColumns"
        :has-below-actions="true"
        class="rental-sales-table"
        autosuggest-list-item-name="name"
        collection-of-list-item-i-d="id"
        @getCollectedList="getCollectedList"
        @rowHoveredHandler="rowHoveredHandler"
        @rowUnHoveredHandler="rowUnHoveredHandler"
    >
      <template #cell(actions)="item">
        <div :id="`td-${item.data.id}`" class="d-flex p-0" style="gap: 8px">
          <feather-icon
              v-b-tooltip.noninteractive.click.bottom
              icon="LTrashIcon"
              class="lightIcon cursor-pointer border-dotted featherIcon"
              :title="$t('Remove')"
              size="30"
              style="padding: 4px"
              @click="remove(item.index)"
          />
          <feather-icon
              v-b-tooltip.noninteractive.click.bottom
              icon="LEyeIcon"
              size="30"
              style="padding: 4px"
              class="lightIcon cursor-pointer border-dotted featherIcon"
              :title="$t('Watch')"
              @click="navigateToProduct(item.data, $event)"
          />
          <feather-icon
              icon="LNoteIcon"
              class="lightIcon cursor-pointer border-dotted featherIcon"
              size="30"
              style="padding: 4px"
              @click="noteHandler(data)"
          />
        </div>
      </template>
      <template #below-actions="{ data }">

        <div
            v-if="allowToPickRentalTimesByLineItem"
            class="position-relative mt-1"
            style="height: 40px;"
        >
          <div
              class="position-absolute"
              style="
                min-width: 1300px;
                width: 100%;
                z-index: 10;
                left: 0;"
          >
            <b-row>
              <b-col cols="2">
                <b-form-checkbox v-model="data.inheritFromOrder" @change="inheritDateFormOrder">
                  {{ $t("Inherit from Order") }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="10" style="padding: 0">
                <div class="d-flex" style="gap: 10px">
                  <LDataPicker
                      @input="onIncrementDecrementAction()"
                      v-model="data.rental_start"
                      class="rental-sales-table-start-date d-flex"
                      name="rental-start"
                      :is-editable="data.inheritFromOrder"
                      :field="{
                      label: 'Rental Start',
                      options: {
                        config: {
                          allowInvalidPreload: true,
                          defaultDate: data.rental_start,
                          minDate: 'today',
                          'is-calendar-icon': true,
                          maxDate: endDateOfInsurance,
                        },
                      },
                    }"
                  />
                  <LTimePickerInput
                      v-model="data.event_information_rental_start_time"
                      :is-editable="data.inheritFromOrder"
                      :name="`rental_start_tim_${data.id}`"
                      class="rental_time_picker"
                      :field="{placeholder: 'No time'}"
                  />
                  <LDataPicker
                      @input="onIncrementDecrementAction()"
                      v-model="data.rental_end"
                      class="rental-sales-table-start-date d-flex"
                      name="rental-end"
                      :is-editable="data.inheritFromOrder"
                      :field="{
                      label: 'Rental End',
                      options: {
                        config: {

                            'is-calendar-icon': true,
                            maxDate: endDateOfInsurance,
                          },
                      },
                    }"
                  />
                  <LTimePickerInput
                      v-model="data.event_information_rental_end_time"
                      :name="`rental_end_time${data.id}`"
                      class="rental_time_picker"
                      :is-editable="data.inheritFromOrder"
                      :field="{placeholder: 'No time'}"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div
            v-if="data.isNoteOpen"
            class="position-relative mt-1" style="height: 40px;"
        >
          <b-form-input
              :ref="`item-note-input-${data.id}`"
              v-model="data.notes"
              class="position-absolute item-note-input"
              style="height: 30px; width: 450px;"
          />
        </div>
        <!--            <div-->
        <!--                v-if="!data.isNoteOpen && data.notes"-->
        <!--                class="position-absolute"-->
        <!--            >-->
        <!--              {{ data.notes }}-->
        <!--            </div>-->

      </template>
      <template #cell(sku)="{ data }">
        <span
            :id="`${'rent-sku' + data.id}`"
            style="cursor: pointer;"
            @click="showTooltip(data)"
        >
          {{ data.sku }}
        </span>
        <l-tool-tip
            :ref="`${'RentLToolTip' + data.id}`"
            placement="bottom"
            :target="`${'rent-sku' + data.id}`"
        >
          <div class="d-flex flex-column">
            <div class="w-100 d-flex justify-content-end">
              <feather-icon
                  icon="XIcon"
                  class="lightIcon cursor-pointer border-dotted featherIcon"
                  size="16"
                  @click="closeTooltip(data)"
              />
            </div>
            <div class="w-100 d-flex justify-content-between">
              <table class="w-100 mt-1">
                <tr>
                  <th/>
                  <th/>

                  <th>{{'Avail.'}}</th>
                  <th>{{'Other WHs'}}</th>

                </tr>
                <tr
                    v-for="(calendarState, index) in calendarStates.warehouse"
                    :key="index"
                >
                  <td class="text-left">{{ calendarState.date }}</td>
                  <td>
                    <div style="width: 20px; height: 1px;"/>
                  </td>
                  <td>{{ calendarState.current_warehouse_count }}</td>
                  <td>{{ calendarState.other_warehouses_count }}</td>
                </tr>
              </table>
            </div>
            <div
                class="d-flex mt-2"
                style="text-decoration: underline;"
            >
              {{ $t('Alternatives') }}
            </div>
            <div
                class="d-flex justify-content-between"
                v-for="(alternative, index) in calendarStates.alternatives"
                :key="index"
            >
              <div class="d-flex justify-content-start">
                {{ alternative.name }}
              </div>
              <div class="d-flex">
                <feather-icon
                    icon="PlusIcon"
                    class="lightIcon cursor-pointer border-dotted featherIcon"
                    size="16"
                    @click="addAlternative(alternative, data)"
                />
                <feather-icon
                    icon="LReloadIcon"
                    class="lightIcon cursor-pointer border-dotted featherIcon"
                    size="16"
                    @click="reloadAlternative(alternative, data)"
                />
              </div>
            </div>
          </div>
        </l-tool-tip>
      </template>
      <template #cell(name)="{ data }">
        <div
            v-b-tooltip.noninteractive.hover
            class="cell-row-name"
            :title="data.name"
        >
          <feather-icon
              v-if="data.type === 'sub-rent'"
              icon="LNonStockIcon"
              size="26"
          />
          {{ data.name }}
        </div>
      </template>
      <template #cell(units)="{ data }">
        <increment-decrement-input
            ref="incrementDecrementInputItemsRental"
            class="d-flex justify-content-center"
            :item="data"
            style="height: 25px; width: 60px; margin: auto"
            item-key-name-for-amount="amount"
            :on-index-comparison="data.id === getTableRowIndex"
            :is-disabled-plus="
              data.type === 'sub-rent' ? data.amount >= data.available : false
            "
            @onIncrementDecrementAction="onIncrementDecrementAction()"
        >
          <template #switch-off-action>
            <div class="switch-off-action">
              {{ data.amount }}
            </div>
          </template>
        </increment-decrement-input>
      </template>
      <template #cell(price)="{ data }">
        <span class="d-flex-right"> {{ formatNumberToCurrencyView(data.price) }} </span>
      </template>
      <template #cell(days)="{ data }">
        <div class="d-flex-right">
          {{ data.days }}
        </div>
      </template>
      <template #cell(discount)="{ data }">
        <span class="d-flex-right"> {{ data.discount }}% </span>
      </template>
      <template #cell(subtotal)="{ data }">
        <span class="d-flex-right"> {{ formatNumberToCurrencyView(data.subtotal) }} </span>
      </template>
      <template #cell(taxes)="{ data }">
        <span class="d-flex-right"> {{ formatNumberToCurrencyView((data.price*data.taxes)/100) }} </span>
      </template>
      <template #cell(total)="{ data }">
        <span class="d-flex-right"> {{ formatNumberToCurrencyView(data.totalPrice) }} </span>
      </template>
      <!--  Footer Cells    -->
      <template #foot(name)="">
        <div>
          {{ $t("TOTAL") }}
        </div>
      </template>
      <template #foot(units)="">
        <div class="d-flex-right">
          {{ getItemKVOfProduct({productKey: "amount"}) }}
        </div>
      </template>
      <template #foot(discount)="">
        <div class="d-flex-right">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "discountPrice"})) }}
        </div>
      </template>
      <template #foot(subtotal)="">
        <div class="d-flex-right">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "subtotal"})) }}
        </div>
      </template>
      <template #foot(taxes)="">
        <div class="d-flex-right">
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "taxes"})) }}
        </div>
      </template>
      <template #foot(total)="">
        <div>
          {{ formatNumberToCurrencyView(getItemKVOfProduct({productKey: "totalPrice"})) }}
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>

<script>
import {BCol, BFormCheckbox, BFormInput, BRow, BSpinner, VBTooltip,} from "bootstrap-vue";
import LTableListCollector from "@/views/components/LTableListCollector.vue";
import {debounce, formatNumberToCurrencyView, sumTotalAmountOfGivenArrayByProperty} from "@core/utils/utils";
import LDataPicker from "@/views/components/DynamicForm/components/LFlatPicker.vue";
import LDraggableTableListCollector from "@/views/components/LDraggableTableListCollector.vue";
import LToolTip from "@/views/components/LToolTip.vue";
import config from "../../../orders/view/rental-sales/components/create/config";
import * as configRentalSale from '../../../orders/view/rental-sales/config'
import Autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'

export default {
  name: "RentalTable",
  components: {
    BSpinner,
    Autosuggest,
    LDraggableTableListCollector,
    BRow,
    BCol,
    BFormInput,
    LDataPicker,
    BFormCheckbox,
    LTableListCollector,
    LToolTip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      getTableRowIndex: null,
      calendarStates: {},
      searchQuery: '',
      autoSuggestLoading: false,
      suggestions: [],
      customer_id: this.$store.state[this.MODULE_NAME].orderInformationForm.customer_id ?? 0
    };
  },
  computed: {
    searchLoading() {
      return this.autoSuggestLoading && this.searchQuery
    },
    orderItemsRental() {
      return this.$store.state[this.MODULE_NAME].orderItemsRentalTable;
    },
    eventInformationForm() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm;
    },
    orderInformationForm() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm;
    },
    eventInformationRentalEnd() {
      return this.eventInformationForm.event_information_rental_end;
    },
    endDateOfInsurance() {
      return this.eventInformationForm.coverage_end_date
    },
    eventInformationRentalStart() {
      return this.eventInformationForm.event_information_rental_start_dispatch;
    },
    eventInformationRentalStartDispatchTime() {
      return this.eventInformationForm
          .event_information_rental_start_dispatch_time;
    },
    eventInformationRentalEndTime() {
      return this.eventInformationForm.event_information_rental_end_time;
    },
    eventInformationDispatchDate() {
      return this.eventInformationForm.event_information_dispatch_date;
    },
    allowToPickRentalTimesByLineItem: {
      get() {
        return this.$store.state[this.MODULE_NAME]
            .allowToPickRentalTimesByLineItem;
      },
      set(bool) {
        this.$store.state[this.MODULE_NAME].allowToPickRentalTimesByLineItem =
            bool;
      },
    },
    canBeSubRented: {
      get() {
        return this.$store.state[this.MODULE_NAME]
            .canBeSubRented;
      },
      set(bool) {
        this.$store.state[this.MODULE_NAME].canBeSubRented =
            bool;
      },
    },
  },
  watch: {
    orderInformationForm(v) {
      if (!(v.customer_id === this.customer_id) && this.customer_id !== 0) {
        this.clearProduct()
        this.customer_id = v.customer_id
      }
    },
  },
  methods: {
    isDisabledAutosuggest() {
      return this.orderInformationForm.customer_id
          && Boolean(this.eventInformationRentalEnd)
          && Boolean(this.eventInformationRentalEndTime)
          && Boolean(this.eventInformationRentalStart)
          && Boolean(this.eventInformationRentalStartDispatchTime)
          && Boolean(this.eventInformationDispatchDate);
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.cust_name_dba
    },
    getSuggestionValue(suggestion) {
      const {item} = suggestion

      return item.cust_name_dba
    },

    fetchResults(searchQuery) {
      this.search(searchQuery, this)
      this.$emit('onInput', searchQuery)
    },

    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),
    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.autoSuggestLoading = true

        this.$store.dispatch(`${this.MODULE_NAME}/${!this.canBeSubRented ? 'rentalOrders' : 'subRentalOrders'}`, {
          search: params,
          customer_id: this.orderInformationForm.customer_id,
          f: {
            is_active: 1,
          },
        }).then(({data}) => {
          const mappedData = data.data.data
          this.suggestions = [{data: mappedData}]
        }).finally(() => {
          this.autoSuggestLoading = false
        })
      }
    },
    selectHandler(suggest) {

        if (suggest.item.price > 0) {
          this.$refs.lTableRef.selectHandler(suggest)
          this.setDateForOrder()
          this.suggestions = []
        } else {
          alert('This product does not rates')
          this.suggestions = []
        }
    },


    onIncrementDecrementAction() {
      const products = this.orderItemsRental.filter(item => item.amount !== 0);

      this.calculatePriceOfProducts({
        productsList: products,
        fieldName: {
          rentalStart: 'rental_start',
          rentalEnd: 'rental_end',
        },
        storeActionKey: "SET_ORDER_ITEMS_RENTAL_TABLE",
      });
    },
    getCollectedList(newProductsList) {

      newProductsList = newProductsList.map((i) => {
        if (newProductsList[newProductsList.length - 1].id === i.id) {
          return {
            ...i,
            amount: 1,
            taxes: i.taxes,
          };
        } else {
          return i;
        }
      });
      this.calculatePriceOfProducts({
        productsList: newProductsList,
        fieldName: {
          rentalStart: 'rental_start',
          rentalEnd: 'rental_end',
        },
        storeActionKey: "SET_ORDER_ITEMS_RENTAL_TABLE",
      });

      // const itemMap = new Map();
      //
      // newProductsList.forEach((item) => {
      //   if (itemMap.has(item.id)) {
      //     // If the item is already in the map, increase its amount
      //     const existingItem = itemMap.get(item.id);
      //     existingItem.amount += item.amount || 1;
      //     itemMap.set(item.id, existingItem);
      //   } else {
      //     itemMap.set(item.id, {
      //       ...item,
      //       amount: item.amount || 1,
      //       taxes: 0,
      //     });
      //   }
      // });
      //
      // // Convert the map back to an array and assign it to the collectionOfListArr
      // this.collectionOfListArr = Array.from(itemMap.values());
      //
      // this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, this.collectionOfListArr);
      //
      // this.calculatePriceOfProducts({
      //   productsList: this.collectionOfListArr,
      //   fieldName: {
      //     startDate: 'start_date',
      //     endDate: 'end_date',
      //   },
      //   storeActionKey: "SET_ORDER_ITEMS_RENTAL_TABLE",
      // });
    },
    remove(index) {
      const updatedList = [...this.orderItemsRental];
      updatedList.splice(index, 1);
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, updatedList);
      // const filteredList = this.orderItemsRental.filter(
      //     (item) => item.product_id !== id
      // );
      // this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, filteredList);
    },
    getItemKVOfProduct({productKey}) {
      return this.sumTotalAmountOfGivenArrayByProperty(
          this.orderItemsRental,
          productKey
      );
    },
    rowHoveredHandler(rowData) {

      if (!this.$refs.incrementDecrementInputItemsRental) return
      const {id, amount} = rowData;
      this.getTableRowIndex = id;
      this.$refs.incrementDecrementInputItemsRental.setValue(amount);
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null;
    },
    fetchedRentals() {
      return this.orderItemsRental;
    },
    inheritDateFormOrder(event) {
      if (event) {
        this.setDateForOrder()
      }
    },
    allowToPickRentalTimesByLineItemChange(event) {
      if (event) {
        this.setDateForOrder()
      }
    },
    setDateForOrder() {
      if (!this.eventInformationRentalStart || !this.eventInformationRentalEnd) return
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`,
          this.orderItemsRental.map((item) => {
            return {
              ...item,
              rental_start: item.inheritFromOrder ? this.eventInformationRentalStart : item.rental_start,
              rental_end: item.inheritFromOrder ? this.eventInformationRentalEnd : item.rental_end,
              event_information_rental_start_time: item.inheritFromOrder ? this.eventInformationRentalStartDispatchTime : item.event_information_rental_start_time,
              event_information_rental_end_time: item.inheritFromOrder ? this.eventInformationRentalEndTime : item.event_information_rental_end_time,
              inheritFromOrder: item.inheritFromOrder,
            };
          }),
      );
      this.onIncrementDecrementAction();
    },
    clearProduct() {
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, [])
    },
    // onAllowToPickRentalTimesByLineItem(event) {
    //   if(!event){
    //     this.setDateForOrder()
    //   }
    //   this.orderItemsRental.forEach(({ id, isNoteOpen, notes }) => {
    //     const tableRowTDS = document.querySelectorAll(`.td-${id}`);
    //     if (event) {
    //       if (isNoteOpen || notes) {
    //         tableRowTDS[0].style.height = "130px";
    //       } else {
    //         tableRowTDS[0].style.height = "90px";
    //       }
    //     } else if (isNoteOpen || notes) {
    //       tableRowTDS[0].style.height = "90px";
    //     } else {
    //       tableRowTDS[0].style.height = "40px";
    //     }
    //   });
    // },
    navigateToProduct({id}, e) {
      this.gotoNewPage(
          {name: "home-catalog-general-info", params: {id}},
          e,
          true
      );
    },
    showTooltip(data) {
      this.$store.dispatch(`${this.MODULE_NAME}/getCalendarState`, {id: data.id}).then(response => {
        const {data} = response.data
        this.calendarStates = data
      })
    },
    closeTooltip(data) {
      this.$refs[`RentLToolTip${data.id}`].hide()
    },
    addAlternative(alternative, data) {
      const products = this.getNewRentalProductList(alternative, data)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, products);
      this.$refs[`RentLToolTip${data.id}`].hide()
    },
    reloadAlternative(alternative, data) {
      this.remove(data)
      const products = this.getNewRentalProductList(alternative, data)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, products);
      this.$refs[`RentLToolTip${data.id}`].hide()
    },
    getNewRentalProductList(alternative, data) {
      const product = this.orderItemsRental.find(item => item.id === alternative.id);
      if (product) {
        return this.orderItemsRental.map(item => {
          if (item.id === alternative.id) {
            return {
              ...item,
              amount: item.amount + 1
            };
          }
          return item;
        });
      } else {
        return [
          ...this.orderItemsRental,
          ...[{
            available: alternative.available,
            discount: alternative.discount,
            id: alternative.id,
            is_active: alternative.is_active,
            name: alternative.name,
            other_locations: alternative.other_locations,
            price: alternative.price,
            replacement_cost: alternative.replacement_cost,
            sku: alternative.sku,
            status: alternative.status,
            type: alternative.type,
            amount: data.amount,
            days: data.days,
            discountPrice: data.discountPrice,
            event_information_rental_end_time: data.event_information_rental_end_time,
            event_information_rental_start_time: data.event_information_rental_start_time,
            inheritFromOrder: data.inheritFromOrder,
            isNoteOpen: data.isNoteOpen,
            notes: data.notes,
            rental_end: data.rental_end,
            rental_start: data.rental_start,
            subtotal: data.subtotal,
            taxes: data.taxes,
            totalPrice: data.totalPrice,
          }]
        ];
      }
    },
    noteHandlerUnFocus({id, isNoteOpen}) {
      const product = this.orderItemsRental.find(
          item => item.id === id,
      )
      product.isNoteOpen = !isNoteOpen
    },
    noteHandler(data) {
      const product = this.orderItemsRental.find(
          item => item.id === data.id,
      )
      product.isNoteOpen = !data.isNoteOpen
    },
    isDisabledAction(data) {
      if (data.type === 'sub-rent'){
        return (data?.price > 0 && data?.available > 0) ?? false
      }
      return data?.price > 0 ?? false
    },
  },
  setup() {
    const {
      tableColumnsRental,
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      rentalTableAutoSuggestTableColumns,
      MODULE_NAME_CATALOG,
    } = config();

    const {calculatePriceOfProducts} = configRentalSale.default()

    return {
      MODULE_NAME,
      ORDER_ITEMS_RENTALS_TABLE_TITLE,
      tableColumnsRental,
      sumTotalAmountOfGivenArrayByProperty,
      rentalTableAutoSuggestTableColumns,
      MODULE_NAME_CATALOG,
      calculatePriceOfProducts,
      formatNumberToCurrencyView,
    };
  },
};
</script>
<style lang="scss">
#rental-product {
  position: relative;
  & .form-control {

  }

  & .spinner {
    position: absolute;
    top: 12px;
    right: 8px;
  }
}
.d-flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 2px 7px;
}

.cell-row-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.rental-sales-table .l-tableList-collector__auto-suggest input {
  background-color: #ccc !important;

  &::placeholder {
    color: #000 !important;
  }
}

.item-note-input {
  height: 34px;
  outline: none;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }

  //.rental-sales-table thead th {
  //  padding: 5px !important;
  //}
}

.rental-sales-table-start-date {
  margin-bottom: 0;
  gap: 3px;

  label {
    font-weight: bold;
  }

  //.form-control {
  //  height: 26px !important;
  //}

  //.flatpickr-input--custom-style .flatpickr-input--calendar {
  //  top: 5px;
  //}
}

.rental_time_picker {
  display: flex;

  .form-control {
    //height: 26px;
    padding: 0 5px !important;
    gap: 5px;
    align-items: center;
  }

  .btn {
    padding: 0 !important;
  }
}

#rental-start-time {
  padding: 0 !important;

  #rental-start-time {
    padding: 0 !important;
  }
}

</style>
