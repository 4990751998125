<template>
  <div>
    <validation-observer
      ref="salesRentalQuoteForm"
    >
      <create-form />
    </validation-observer>
    <portal to="body-footer">
      <form-bottom
        :is-update="true"
        :form-ref="$refs"
      />
    </portal>
  </div>
</template>

<script>

import CreateForm from '@/views/main/orders/view/rental-sales/components/create/Form.vue'
import FormBottom
from '@/views/main/orders/view/rental-sales/components/create/components/FormBottom.vue'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'RentalSalesDraft',
  components: {
    FormBottom,
    CreateForm,
    ValidationObserver,
  },
}
</script>

<style scoped lang="scss"></style>
