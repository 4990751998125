import { render, staticRenderFns } from "./Draft.vue?vue&type=template&id=2f227fd9&scoped=true"
import script from "./Draft.vue?vue&type=script&lang=js"
export * from "./Draft.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f227fd9",
  null
  
)

export default component.exports